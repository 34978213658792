import React from 'react';

import { Button, Container, Jumbotron } from 'reactstrap';
import PropTypes from 'prop-types';

const Home = ({ isAuthenticated }) => (
  <Container>
    <Jumbotron className="mt-3 text-center">
      Mbot Home
        <div className="mt-2">
          {!isAuthenticated && (
            <Button
              className="btn-primary"
              tag="a"
              href="/users/auth/keycloakopenid"
            >
              Login / Register
            </Button>
          )}
        </div>
    </Jumbotron>
  </Container>
);

Home.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Home;

import React from 'react';

import { Button } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';

import { Notifier, fieldValidations } from '../shared/utils';
import { renderTextField } from '../shared/fields';
import { updateUserPassword } from '../../ajax/users';

const PasswordForm = () => (
  <Formik
    initialValues={{
      password: '',
      password_confirmation: '',
    }}
    validate={values => {
      const errors = {};

      if (
        !isEmpty(values.password_confirmation) &&
        !isEqual(values.password_confirmation, values.password)
      ) {
        errors.password_confirmation = 'Passwords do not match';
      }

      return errors;
    }}
    onSubmit={(values, actions) => {
      updateUserPassword(values).then(res => {
        actions.resetForm();
        actions.setSubmitting(false);
        if (res) {
          Notifier(
            'success',
            'User Updated',
            'We have successfully updated your information.'
          );
          return;
        }
        Notifier(
          'danger',
          'User Update Failed',
          'We could not update the user info'
        );
      });
    }}
  >
    {() => (
      <Form>
        <Field
          label="Password"
          type="password"
          name="password"
          validate={fieldValidations.required}
          placeholder="Enter Password"
          component={renderTextField}
        />

        <Field
          label="Confirm Password"
          type="password"
          name="password_confirmation"
          validate={fieldValidations.required}
          placeholder="Enter Password"
          component={renderTextField}
        />

        <Button type="submit">Submit</Button>
      </Form>
    )}
  </Formik>
);
export default PasswordForm;

import { isEmpty, isEqual, isNaN } from 'lodash';
import { store } from 'react-notifications-component';

export const fieldValidations = {
  required: value =>
    !isEmpty(value) || typeof value === 'number' ? undefined : 'Required',
  maxLength: max => value =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined,
  minLength: min => value =>
    value && value.length < min
      ? `Must be ${min} characters or more`
      : undefined,
  number: value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined,
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  passwordConfirmation: (value, all) =>
    isEqual(all.password, value) ? undefined : "Passwords don't match",
};

export const Notifier = (type, title, message) =>
  store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });

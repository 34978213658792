import axios from 'axios';

export const updateUserPassword = async params =>
  axios
    .put('/users/password.json', params)
    .then(res => res.data.success)
    .catch(() => false);

export const updateUserInfo = async params =>
  axios
    .put('/users.json', params)
    .then(res => res.data.success)
    .catch(() => false);

import React from 'react';

import ReactNotifications from 'react-notifications-component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);

const DefaultLoads = () => <ReactNotifications />;

export default DefaultLoads;

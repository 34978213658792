import React from 'react';

import { Input, Button, Col, FormGroup, Row, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';

import { Notifier, fieldValidations } from '../shared/utils';
import { renderTextField } from '../shared/fields';
import { updateUserInfo } from '../../ajax/users';

const InfoForm = ({ user }) => (
  <Formik
    initialValues={{
      firstname: user.firstname,
      lastname: user.lastname,
    }}
    onSubmit={(values, actions) => {
      updateUserInfo(values).then(res => {
        actions.setSubmitting(false);
        if (res) {
          Notifier(
            'success',
            'User Updated',
            'We have successfully updated your information.'
          );
          return;
        }
        Notifier(
          'danger',
          'User Update Failed',
          'We could not update the user info'
        );
      });
    }}
  >
    {() => (
      <Form className="sign-in-up-body">
        <Row>
          <Col lg={6}>
            <Field
              component={renderTextField}
              name="firstname"
              type="text"
              label="Firstname"
              validate={fieldValidations.required}
            />
          </Col>
          <Col lg={6}>
            <Field
              component={renderTextField}
              name="lastname"
              type="text"
              label="Lastname"
              validate={fieldValidations.required}
            />
          </Col>
        </Row>
        <FormGroup>
          <Label>Email</Label>
          <Input value={user.email} disabled />
        </FormGroup>
        <div className="sign-in-up-button">
          <div className="button-sign-in-up">
            <Button type="submit">Update</Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

InfoForm.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
};

export default InfoForm;

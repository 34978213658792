import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const FontAwesome = ({ name, prefix }) => (
  <FontAwesomeIcon icon={{ iconName: name, prefix }} />
);

FontAwesome.defaultProps = {
  prefix: 'fas',
};

FontAwesome.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

export default FontAwesome;

import React from 'react';

import { FormGroup, Input, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

const invalidFeedback = feedback => (
  <div className="invalid-feedback">{feedback}</div>
);

export const renderTextField = ({ form, field, type, label, className }) => (
  <FormGroup className={className || ''}>
    {!isEmpty(label) && <Label dangerouslySetInnerHTML={{ __html: label }} />}
    <div className="flex-grow-1">
      <Input
        {...field}
        className={classnames({
          'is-invalid': form.touched[field.name] && form.errors[field.name],
        })}
        type={type}
      />
      {form.touched[field.name] &&
        !isEmpty(form.errors[field.name]) &&
        invalidFeedback(form.errors[field.name])}
    </div>
  </FormGroup>
);
